<template>
    <div class="store-wrapper">
        <el-scrollbar class="store-container" :native="false">
            <div class="store-dianzhao">
                <img :src="storeInfo.dianzhao ? storeInfo.dianzhao : require('../../../assets/images/noimg.png')" alt="">
            </div>
            <div class="store-tab-wrapper">
                <div class="store-tab">
                    <div class="tab-left flex">
                        <div class="item">
                            <div class="class-levelOne">首页</div>
                        </div>
                        <div class="item" v-for="(classItem, classIndex) in storeInfo.store_class" :key="`classList_${classIndex}`">
                            <div class="class-levelOne">
                                {{classItem.store_class_name}}<i v-if="classItem.children.length > 0" class="el-icon-caret-bottom"></i>
                            </div>
                            <div class="class-levelTwo" v-if="classItem.children.length > 0">
                                <div class="item-two"
                                     v-for="(classTwoItem, classTwoIndex) in classItem.children"
                                     :key="`classTwo_${classTwoIndex}`">{{classTwoItem.store_class_name}}</div>
                            </div>
                        </div>
                    </div>
                    <el-input suffix-icon="el-icon-search" class="tab-search" v-model="search" style="width: 205px"></el-input>
                </div>
            </div>
            <swiper ref="mySwiper" :options="swiperOptions" class="store-banner">
                <swiper-slide class="item" v-for="(bannerItem, bannerIndex) in storeInfo.bannerList" :key="bannerIndex">
                    <div class="img-cover">
                        <img :src="bannerItem" alt="">
                    </div>
                </swiper-slide>
                <swiper-slide class="item" v-if="storeInfo.bannerList.length === 0">
                    <div class="img-cover">
                        <img :src="require('../../../assets/images/noimg_banner.png')" alt="">
                    </div>
                </swiper-slide>
                <div class="topic-swiper-button-prev" slot="button-prev">
                    <i class="iconfont">&#xe8ef;</i>
                </div>
                <div class="topic-swiper-button-next" slot="button-next">
                    <i class="iconfont">&#xe8f1;</i>
                </div>
            </swiper>
            <div class="store-goods">
                <div class="good-head">
                    <div class="item current">销量</div>
                    <div class="item">价格</div>
                    <div class="item">好评度</div>
                    <div class="item">上架时间</div>
                </div>
                <div class="goods-list">
                    <div class="good-item" v-for="(goodItem, goodIndex) in storeInfo.goodList" :key="`goodList_${goodIndex}`">
                        <router-link target="_blank" :to="{path: '/student/store/gooddetail', query: {id: goodItem.id}}" class="good-cover">
                            <img :src="goodItem.image_url" alt="">
                        </router-link>
                        <div class="good-content">
                            <router-link target="_blank"
                                         :to="{path: '/student/store/gooddetail', query: {id: goodItem.id}}"
                                         class="good-title text-overflow" :title="goodItem.goodTitle">{{goodItem.goods_name}}</router-link>
                            <p class="good-price">
                                <i class="symbol">&yen;</i>
                                <span class="price">{{goodItem.goods_price}}</span>
                            </p>
                            <p class="good-comment">已有0人评价</p>
                            <div class="good-btn">
                                <el-button size="small" class="btn-red-line">购买</el-button>
                                <el-button size="small" class="btn-red-line">关注</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="no-data" style="line-height: 100px;text-align: center;color: #999; width: 100%;"
                         v-if="storeInfo.goodList.length === 0">暂无数据</div>
                </div>
            </div>
            <el-pagination class="pager-center" style="margin: 30px 0"
                           v-if="storeInfo.goodList.length > 0"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="pagesCurrentChange">
            </el-pagination>
        </el-scrollbar>
    </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: "Index",
    data() {
        return {
            storeInfo: {
                dianzhao: '',
                bannerList: [],
                goodList: [],
                store_class: [],
                classValue: '',
            },
            search: '',
            swiperOptions: {
                autoplay: true,
                pagination: {
                    el: '.swiper-pagination'
                },
                loop: true,
                navigation: {
                    nextEl: '.topic-swiper-button-next',
                    prevEl: '.topic-swiper-button-prev'
                },
            },
            listPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 30,
            },
        }
    },
    created() {
        this.getStoreInfo();
    },
    components: {
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    methods: {
        pagesCurrentChange(){},
        getStoreInfo(){
            let param = {
                competition_id: this.$route.query.cid,
                user_id: this.$route.query.sid
            }
            this.$http.axiosGetBy(this.$api.previewStore, param,(res) => {
                if(res.code === 200){
                    this.storeInfo.dianzhao = res.data.danzhao_image;
                    this.storeInfo.bannerList = res.data.banner_image;
                    this.storeInfo.goodList = res.data.goods;
                    this.storeInfo.store_class = res.data.store_class;
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                console.log(err)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.store-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #F2F2F2;
}
.store-container {
    /*min-width: 1200px;*/
    height: 100vh;
    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            /*max-width: 1200px;*/
            min-width: 1200px;
        }
    }
    .store-dianzhao {
        height: 0;
        padding-bottom: 6.25%;
        position: relative;
        background: #fafafa;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
        }
    }
    .store-tab-wrapper {
        height: 30px;
        background: #fff;
        .store-tab {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item {
                color: #666;
                transition: all .3s;
                position: relative;
                line-height: 30px;
                .class-levelOne {
                    padding: 0 30px;
                    cursor: pointer;
                    i {
                        margin-left: 6px;
                    }
                    &:hover {
                        color: #FF0000;
                    }
                }
                .class-levelTwo {
                    display: none;
                    position: absolute;
                    top: 29px;
                    left: 25px;
                    /*right: 30px;*/
                    z-index: 99;
                    background: #fff;
                    border: 1px solid #000;
                    transition: all .3s;
                    min-width: 80px;
                    .item-two {
                        cursor: pointer;
                        line-height: 24px;
                        padding: 0 10px;
                        margin-top: 5px;
                        transition: all .3s;
                        white-space: nowrap;
                        &:first-child {
                            margin-top: 0;
                        }
                        &:hover {
                            background: #eee;
                        }
                    }
                }
                &:hover {
                    .class-levelTwo {
                        display: block;
                    }
                }
            }
        }
    }
    .store-banner {
        width: 100%;
        position: relative;
        .item {
            background: #fafafa;
            .img-cover {
                width: 100%;
                height: 0;
                padding-bottom: 32.8125%;
                position: relative;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .topic-swiper-button-prev, .topic-swiper-button-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, .5);
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            outline: none;
            z-index: 99;
            border-radius: 50%;
            .iconfont {
                font-size: 30px;
                display: block;
                color: #fff;
                transition: all .3s;
            }
            &:hover {
                .iconfont {
                    color: #ff0000;
                }
            }
        }
        .topic-swiper-button-prev {
            left: 80px;
        }
        .topic-swiper-button-next {
            right: 80px;
        }
    }
    .store-goods {
        width: 1200px;
        margin: 16px auto 0;
        .good-head {
            position: relative;
            display: flex;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: #E5E5E5;
            }
            .item {
                z-index: 1;
                height: 30px;
                font-size: 14px;
                padding: 0 12px;
                line-height: 30px;
                color: #666;
                margin-right: 10px;
                border: 1px solid #DCDCDC;
                transition: all .3s;
                cursor: pointer;
                &.current {
                    color: #1122D8;
                }
                &:hover {
                    border-color: #1122D8;
                }
            }
        }
        .goods-list {
            display: flex;
            flex-wrap: wrap;
            //margin: 0 -25px 0;
            .good-item {
                /*width: calc(25% - 66.666666px);*/
                width: 250px;
                //margin: 20px 25px;
                margin: 20px 0 20px 66.666666px;
                &:nth-of-type(4n + 1) {
                    margin-left: 0;
                }
                .good-cover {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    position: relative;
                    display: block;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .good-content {
                    .good-title {
                        margin-top: 20px;
                        color: #333;
                        transition: all .3s;
                        display: block;
                        &:hover {
                            color: #FF0000;
                        }
                    }
                    .good-price {
                        margin-top: 20px;
                        color: #ff0000;
                        .symbol {
                            font-size: 12px;
                            margin-right: 4px;
                            font-style: normal;
                        }
                        .price {
                            font-weight: bold;
                            font-size: 16px;
                        }
                    }
                    .good-comment {
                        margin-top: 12px;
                        color: #666;
                    }
                    .good-btn {
                        margin-top: 12px;
                        .el-button {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
    /deep/ .pager-center {
        .el-pager li {
            background: transparent;
        }
        button:disabled {
            background-color: transparent;
        }
        .btn-next, .btn-prev {
            background: center center no-repeat transparent;
        }
    }
}
/deep/ .tab-search {
    .el-input__inner {
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
    }
    .el-input__icon {
        line-height: 24px;
    }
    &.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: #ff0000;
        outline: 0;
    }
}
/deep/ p {
    margin: 0;
}
</style>